.container {
    width: 100%;
    height: 100%;
    background: url("../../static/images/login_bg.png") no-repeat;
    background-size: cover;
}

.logo {
    position: fixed;
    top: 60px;
    left: 60px;
}

.logo img {
    width: 128px;
    height: 42px;
}

.logoDivider {
    display: inline-block;
    width: 1px;
    height: 30px;
    background-color: rgba(255, 255, 255, 0.4);
    margin: 0 20px;
    vertical-align: middle;
}

.logoText {
    font-size: 30px;
    font-weight: 400;
    color: rgba(255,255,255,1);
    display: inline-block;
    vertical-align: middle;
}

.body {
    width: 1000px;
    height: 100%;
    margin: 0 auto;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: center;
}

.form {
    box-shadow: 0px 4px 20px 0px rgba(0,0,0,0.3);
    border-radius: 10px;
    background-color: #fff;
    padding-top: 20px;
}

.form iframe {
    border-radius: 10px;
}